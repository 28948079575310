import type { NewsletterSignup, NewsletterSignupResponse } from '~/types';
import { NewsletterSignupModel } from '~/models';
import { useApiUtils } from './useApiUtils';
import type { NitroFetchOptions } from 'nitropack';
import { useCustomFetch } from '~/composables';

export function useNewsletter() {
    const ctx = useNuxtApp();
    const apiBasePath = `/v1/newsletters`;
    const useApiUtilsObj = useApiUtils();
    const $_fetch = useCustomFetch();

    async function signUp(signupObj: NewsletterSignup): Promise<NewsletterSignupModel | Error> {
        const url = `${apiBasePath}/`;
        const config: NitroFetchOptions<string> = {
            method: 'POST',
            body: signupObj
        };

        try {
            const response = await $_fetch.raw<NewsletterSignupResponse>(url, config);

            if (response.status === 204) {
                return NewsletterSignupModel.toPlainObject(response._data);
            }

            return useApiUtilsObj.getErrorObj(new Error(response._data?.title));
        } catch (err) {
            return useApiUtilsObj.getErrorObj(err);
        }
    }

    return {
        signUp
    };
}
